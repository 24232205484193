import {
  getModuleSettingValue,
  hasModules,
} from '@/helpers/accessControl/module'

export default {
  hasModules(state) {
    return (moduleIdsSearched) =>
      hasModules(state.activeModules, moduleIdsSearched)
  },

  moduleSettingValue(state) {
    return (moduleId, settingId) =>
      getModuleSettingValue({
        activeModules: state.activeModules,
        moduleId,
        settingId,
      })
  },

  moduleSupportSettings(state, getters, rootState, rootGetters) {
    const studentEducationType = rootGetters['student/profile/educationType']
    const clientEducationType = rootGetters['client/students/educationType']
    const isStudent = rootGetters['common/user/isStudent']

    const educationType = isStudent ? studentEducationType : clientEducationType

    const educationTypeStringValue =
      Object.keys(educationType)
        ?.find((key) => educationType[key] === true)
        ?.toLowerCase() || 'extramural'

    return getters.moduleSettingValue('support', educationTypeStringValue) ?? []
  },
}
