import { setCookie } from '@/helpers/cookie'
import { ApiIsAuthorized } from 'ApiRest/Api/Auth/IsAuthorized'

export default {
  async fetchIsAuthorized({ commit }) {
    const { data } = await ApiIsAuthorized.post()

    if (data !== null) {
      commit('setUserValue', {
        key: 'id',
        value: data.id,
      })

      commit('setUserValue', {
        key: 'role',
        value: data?.baseRole ?? 'anonymous',
      })

      commit('setUserValue', {
        key: 'email',
        value: data?.email,
      })

      setCookie('ibls_id', data.id)
    } else {
      commit('resetState')
    }
  },

  async changeLocale({ commit, dispatch }) {
    await dispatch('common/locale/fetchLangOptions', null, {
      root: true,
    })

    dispatch('common/locale/setLangDefault', null, {
      root: true,
    })

    commit('common/locale/setUserType', 'unauthorized', {
      root: true,
    })

    commit('common/locale/setInstitutionType', 'school', {
      root: true,
    })

    await dispatch('common/locale/changeLocale', null, {
      root: true,
    })
  },
}
