import { RESET_STATE } from '@/helpers/ResetState'
import HttpHeaders from 'Api/const/HttpHeaders'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTheLesson(state, theLesson) {
    state.theLessonCached[theLesson.id] = theLesson
  },

  setLessons(state, { mode, data }) {
    if (mode === 'all') {
      state.lessons.push(...data)
    } else if (mode === 'testOnly') {
      state.lessonsTestOnly.push(...data)
    } else if (mode === 'unpassedOnly') {
      state.lessonsUnpassedOnly.push(...data)
    }
  },

  clearLessons(state) {
    state.lessons = []
    state.lessonsTestOnly = []
    state.lessonsUnpassedOnly = []

    state.lessonsPagination = {
      all: {
        page: 1,
        pagesTotal: 1,
      },
      testOnly: {
        page: 1,
        pagesTotal: 1,
      },
      unpassedOnly: {
        page: 1,
        pagesTotal: 1,
      },
    }
  },

  setAllSubjectsNames(state) {
    const subjectsMap = new Map()
    const sources = [
      state.lessons,
      state.lessonsTestOnly,
      state.lessonsUnpassedOnly,
    ]

    const addSubjects = (lessons = []) => {
      lessons.forEach((lesson) => {
        if (lesson.subject?.name) {
          subjectsMap.set(lesson.subject.id, {
            label: lesson.subject.name,
            id: lesson.subject.id,
          })
        }
      })
    }

    sources.forEach((source) => addSubjects(source))

    state.subjectNames = Array.from(subjectsMap.values())
  },

  setLessonsPagination(state, { mode, headers }) {
    const page = Number(headers[HttpHeaders.PAGINATION_PAGE]) || 1
    const pagesTotal = Number(headers[HttpHeaders.PAGINATION_PAGES_TOTAL]) || 1

    if (mode === 'all') {
      state.lessonsPagination.all.page = page
      state.lessonsPagination.all.pagesTotal = pagesTotal
    } else if (mode === 'testOnly') {
      state.lessonsPagination.testOnly.page = page
      state.lessonsPagination.testOnly.pagesTotal = pagesTotal
    } else if (mode === 'unpassedOnly') {
      state.lessonsPagination.unpassedOnly.page = page
      state.lessonsPagination.unpassedOnly.pagesTotal = pagesTotal
    }
  },

  setLessonsFirstWeek(state, payload = []) {
    state.lessonsFirstWeek = payload
  },
}
