const initDomains = function () {
  const priorityDomains = JSON.parse(process.iblsEnv.IBLS_PRIORITY_DOMAINS)

  /**
   * @param {string} apiDomain
   * @returns {string}
   */
  const getApiUrl = (apiDomain) =>
    `${process.iblsEnv.IBLS_API_PROTOCOL}://${apiDomain}`

  priorityDomains.forEach((domain) => {
    const hosts = [
      domain.main,
      domain.admin,
      domain.mobile,
      domain.showcase,
      domain.chat,
    ]

    if (hosts.includes(window.location.host)) {
      window.domains = {
        main: domain.main,
        admin: domain.admin,
        mobile: domain.mobile,
        showcase: domain.showcase,
        chat: domain.chat,
        apiUrl: getApiUrl(domain.api),
        protocol: process.iblsEnv.IBLS_API_PROTOCOL || 'https',
      }

      // TODO: https://redmine.ibls.tech/issues/1989
      //  необходимо добавить в .env в IBLS_PRIORITY_DOMAINS:
      //  "showcaseApiUrl":"api.ibls.courses"
      if (window.location.host === domain.showcase && domain.showcaseApiUrl) {
        window.domains.apiUrl = getApiUrl(domain.showcaseApiUrl)
      }
    }
  })

  if (!window.domains) {
    const domainsAdminExtra = [
      process.iblsEnv.IBLS_E_SECRETARIAT_DOMAIN,
      process.iblsEnv.IBLS_E_LIBRARY_DOMAIN,
    ]
    const isHostDomainsAdminExtra = domainsAdminExtra.includes(
      window.location.host,
    )

    const getMainDomain = function () {
      const mainParts = window.location.host.split('.')

      const isAdminSubdomain =
        mainParts[0] === process.iblsEnv.IBLS_ADMIN_SUBDOMAIN ||
        window.location.host === process.iblsEnv.IBLS_ADMIN_DOMAIN

      if (isAdminSubdomain) {
        mainParts.shift()

        return mainParts.join('.')
      }

      return window.location.host
    }

    let main = process.iblsEnv.IBLS_MAIN_DOMAIN || getMainDomain()

    const admin = isHostDomainsAdminExtra
      ? window.location.host
      : process.iblsEnv.IBLS_ADMIN_DOMAIN ||
        `${process.iblsEnv.IBLS_ADMIN_SUBDOMAIN}.${main}`

    const mobile =
      process.iblsEnv.IBLS_MOBILE_DOMAIN ||
      `${process.iblsEnv.IBLS_MOBILE_SUBDOMAIN}.${main}`

    const showcase =
      process.iblsEnv.IBLS_SHOWCASE_DOMAIN ||
      `${process.iblsEnv.IBLS_SHOWCASE_SUBDOMAIN}.${main}`

    const api =
      process.iblsEnv.IBLS_API_DOMAIN ||
      `${process.iblsEnv.IBLS_API_SUBDOMAIN}.${main}`

    const chat =
      process.iblsEnv.IBLS_CHAT_DOMAIN ||
      `${process.iblsEnv.IBLS_CHAT_SUBDOMAIN}.${main}`

    if (isHostDomainsAdminExtra) {
      main = process.iblsEnv.IBLS_MAIN_PLATFORM
    }

    window.domains = {
      main,
      admin,
      mobile,
      showcase,
      chat,
      apiUrl: getApiUrl(api),
      protocol: process.iblsEnv.IBLS_API_PROTOCOL ?? 'https',
    }
  }
}

initDomains()
